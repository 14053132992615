import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  ListItemIcon
} from '@mui/material';
// components
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import CommonDialog from '../components/CommonDialog';
import { getErrorMessage } from '../utils/appUtils';
import {
  NO_USERS_FOUND,
  INACTIVE,
  ACTIVE,
  USER_ACTIVATED,
  USER_DEACTIVATED,
  USER,
  ACTIVE_USER,
  INACTIVE_USER
} from '../utils/strings';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { fetchUserList } from '../actions/adminActions/userList';
import { updateUser } from '../actions/adminActions/updateUser';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { exportUserList } from '../actions/adminActions/exportUsers';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'lifetimeMember', label: 'Lifetime member', alignRight: false },
  { id: '' }
];

const options = [
  { value: 'app', label: 'App Users' },
  { value: 'admin', label: 'Influencer Users' }
];

const subscriptionOptions = [
  { value: 'All', label: 'All Users' },
  { value: 'Active', label: 'Active Subscription' },
  { value: 'InActive', label: 'In Active Subscription' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        (_user.name && _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_user.email && _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function User(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userListData, setUserListData] = useState([]);
  const [activeInActiveDialog, setActiveInActiveDialog] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserType, setSelectedUserType] = useState(
    localStorage.getItem('userType') || 'admin'
  );
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    localStorage.getItem('subscriptionType') || 'Active'
  );

  const navigate = useNavigate();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    props.fetchUserList(selectedUserType, selectedSubscriptionType, (err) => onError(err));
  }, [props.updateUserData, selectedUserType, selectedSubscriptionType]);

  useEffect(() => {
    const data = props.userListData ? props.userListData : [];
    setUserListData(data);
  }, [props.userListData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userListData.length) : 0;

  const filteredUsers = applySortFilter(userListData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const onSuccessUpdateUser = (status) => {
    enqueueSnackbar(status === 'ACTIVE' ? USER_ACTIVATED : USER_DEACTIVATED, {
      variant: 'success'
    });
  };

  const hadleActiveInActiveUser = () => {
    const data = {
      id: selectedUser._id,
      accountStatus: selectedUser.accountStatus === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'
    };
    props.updateUser(
      data,
      setActiveInActiveDialog,
      (status) => onSuccessUpdateUser(status),
      (err) => onError(err)
    );
  };

  const handleExport = (userType, status) => {
    props.exportUserList(status, userType, (err) => onError(err));
  };

  return (
    <Page title="User | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {selectedUserType === 'admin' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/add/user"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add User
            </Button>
          )}
        </Stack>
        {props.userListLoading || props.exportUserLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Card>
              <UserListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                searchPlaceholder="Search user..."
                isSelection
                placeholderSelection="Select..."
                selectedUserType={selectedUserType}
                setSelectedUserType={setSelectedUserType}
                selectionOption={options}
                selectedSubscriptionType={selectedSubscriptionType}
                setSelectedSubscriptionType={setSelectedSubscriptionType}
                subscriptionOption={subscriptionOptions}
              />
              {selectedUserType === 'app' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '23px'
                  }}
                >
                  {(selectedSubscriptionType === 'Active' ||
                    selectedSubscriptionType === 'All') && (
                    <>
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="ph:export" />}
                        onClick={() => handleExport('paid', 'Active')}
                        style={{ marginRight: '10px' }}
                      >
                        Export subscriber Users
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="ph:export" />}
                        onClick={() => handleExport('free', 'Active')}
                      >
                        Export Trial Users
                      </Button>
                    </>
                  )}
                  {(selectedSubscriptionType === 'InActive' ||
                    selectedSubscriptionType === 'All') && (
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="ph:export" />}
                      onClick={() => handleExport('', 'InActive')}
                      style={{ marginLeft: '10px' }}
                    >
                      Export Inactive Users
                    </Button>
                  )}
                </div>
              )}
              {userListData.length > 0 ? (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={userListData.length}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {filteredUsers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { _id, name, email, personalCode, phoneNumber, accountStatus } =
                                row;

                              return (
                                <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                                  <TableCell component="th" scope="row">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">{email}</TableCell>
                                  <TableCell align="left">{phoneNumber}</TableCell>
                                  <TableCell align="left">
                                    {personalCode.lifetimeMember ? 'Yes' : 'No'}
                                  </TableCell>
                                  <TableCell align="right">
                                    <ListItemIcon>
                                      <Iconify
                                        icon="eva:edit-fill"
                                        width={24}
                                        height={24}
                                        onClick={() => {
                                          navigate('/dashboard/edit/user', {
                                            state: { data: row }
                                          });
                                        }}
                                      />
                                    </ListItemIcon>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor:
                                          !accountStatus || accountStatus === 'ACTIVE'
                                            ? '#FF4842'
                                            : '#00AB55',
                                        width: 90
                                      }}
                                      onClick={() => {
                                        setActiveUser(accountStatus === 'ACTIVE');
                                        setSelectedUser({ _id, accountStatus });
                                        setActiveInActiveDialog(true);
                                      }}
                                    >
                                      {!accountStatus || accountStatus === 'ACTIVE'
                                        ? INACTIVE
                                        : ACTIVE}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userListData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '30px 0px' }}>
                  <Typography variant="h6" align="center">
                    {NO_USERS_FOUND}
                  </Typography>
                </Box>
              )}
            </Card>
          </div>
        )}
      </Container>
      <CommonDialog
        open={activeInActiveDialog}
        close={() => setActiveInActiveDialog(false)}
        onSuccess={() => hadleActiveInActiveUser()}
        dialogTitle={USER}
        dialogMessage={activeUser ? INACTIVE_USER : ACTIVE_USER}
        loading={props.updateUserLoading}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  userListData: state.userListData.data,
  userListLoading: state.userListData.loading,

  updateUserData: state.updateUserData.data,
  updateUserLoading: state.updateUserData.loading,

  exportUserLoading: state.exportUserData.loading
});

export default connect(mapStateToProps, {
  fetchUserList,
  updateUser,
  exportUserList
})(User);
