import axios from 'axios';
import { FORGOT_PASSWORD } from '../../constants';

export const forgotPassword = (data, toastMessage, onError) => (dispatch) => {
  dispatch(addPassword());
  axios
    .post(`auth/forgotPassword`, data)
    .then((res) => {
      dispatch(setPassword(res));
      toastMessage(res.data);
    })
    .catch((err) => {
      dispatch(errorPassword(err.response));
      onError(err.response);
    });
};

export const addPassword = () => ({
  type: FORGOT_PASSWORD.LOAD
});

export const setPassword = (data) => ({
  type: FORGOT_PASSWORD.SUCCESS,
  payload: data.data
});

export const errorPassword = (error) => ({
  type: FORGOT_PASSWORD.FAIL,
  payload: error
});
