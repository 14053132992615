import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  ListItemIcon
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
// import CommonDialog from '../components/CommonDialog';
import { getErrorMessage } from '../utils/appUtils';
import { NO_EXERCISE_FOUND } from '../utils/strings';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead } from '../sections/@dashboard/user';
import { fetchExerciseList, setExerciseList } from '../actions/adminActions/exerciseList';
import AddEditImageBox from '../components/AddEditImageBox';
import ImageViewer from '../components/ImageViewer';
import { deleteFile } from '../actions/adminActions/deleteFile';
import { updateExercise } from '../actions/adminActions/updateExercise';
import { uploadFile } from '../actions/adminActions/uploadFile';
import Textbox from '../components/Textbox';
import ExerciseVideoDialog from '../components/ExerciseVideoDialog';
import ExerciseDescriptionDialog from '../components/ExerciseDescriptionDialog';
import MuiltiSelectExerciseDialog from '../components/MultiSelectExerciseDialog';

// ==== Table Header ====
const TABLE_HEAD = [
  { id: 'exerciseName', label: 'Exercise Name', alignRight: false },
  { id: 'image', label: 'Image', isActions: true },
  { id: 'video', label: 'Video', isActions: true },
  { id: 'description', label: 'Description', isActions: true },
  { id: 'programRecommendation', label: 'Recommendation', isActions: true },
  { id: 'swapExercises', label: 'Swap exercises', isActions: true }
];

// ==== Table SortData Common Function ====
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Exercise(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('exerciseName');
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [exerciseListData, setExerciseListData] = useState([]);
  // const [selectedUserType, setSelectedUserType] = useState(
  //   localStorage.getItem('userType') || 'admin'
  // );
  const [selectedId, setSelectedId] = useState('');
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [openExerciseDialog, setOpenExerciseDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [isRecommendation, setIsRecommendation] = useState(false);

  const inputFile = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  // ==== Pagination Functions ====
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredExercise = applySortFilter(exerciseListData, getComparator(order, orderBy));

  const isExerciseNotFound = filteredExercise.length === 0;

  // ===== Implement data manipulation functions ====
  useEffect(() => {
    props.fetchExerciseList(rowsPerPage, page + 1, onError);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (Object.keys(props.exerciseListData)?.length > 0) {
      const newData = props.exerciseListData;
      setExerciseListData(newData?.exercises);
      setPageCount(newData.totalRecords);
    }
  }, [props.exerciseListData]);

  const updateExerciseData = (id, data, exerciseImage) => {
    const newExerciseData = { ...data, exerciseImage: exerciseImage || '' };
    if (id) {
      props.updateExercise(id, newExerciseData, onError, async () => {
        let newData = [...exerciseListData];
        newData = newData?.map((el) => {
          if (el?._id === id) {
            el.exerciseImage = exerciseImage;
          }
          return el;
        });
        setSelectedId('');
        const newExerciseData = { ...props.exerciseListData, exercises: newData };
        dispatch(setExerciseList(newExerciseData));
      });
    }
  };

  const handleDeleteFile = (id, url, rowData) => {
    const data = { url };
    props.deleteFile(data, onError, async () => {
      await updateExerciseData(id, rowData, '');
    });
  };

  const handleFileUpload = (event, id, rowData) => {
    const file = event.target.files[0];
    props.uploadFile(file, onError, async (data) => {
      const newImageUrl = data?.url;
      await updateExerciseData(id, rowData, newImageUrl);
    });
  };

  const handleChangeDescription = (e, id) => {
    let newData = structuredClone(exerciseListData);
    newData = newData?.map((el) => {
      if (el?._id === id) {
        el.exerciseInstructions = e.target.value;
      }
      return el;
    });
    const newExerciseData = { ...props.exerciseListData, exercises: newData };
    dispatch(setExerciseList(newExerciseData));
  };

  const saveDescription = (id, value) => {
    setSelectedId(id);
    const data = { exerciseInstructions: value };
    props.updateExercise(id, data, onError, async () => {
      setSelectedId('');
    });
  };

  return (
    <Page title="User | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Exercise
          </Typography>
          {/* {selectedUserType === 'admin' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/add/user"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Exercise
            </Button>
          )} */}
        </Stack>
        {props.exerciseListLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {exerciseListData.length > 0 ? (
              <Card>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={exerciseListData.length}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredExercise.map((row) => {
                          const {
                            _id,
                            exerciseName,
                            exerciseImage,
                            exerciseInstructions,
                            programRecommendation
                          } = row;
                          return (
                            <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                              <TableCell scope="row">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {exerciseName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  // justifyContent="flex-end"
                                >
                                  {exerciseImage ? (
                                    <AddEditImageBox
                                      image={exerciseImage}
                                      onDeleteClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedId(_id);
                                        handleDeleteFile(_id, exerciseImage, row);
                                      }}
                                      onImageClick={() => {
                                        setShowImageViewer(true);
                                        setSelectedImage(exerciseImage);
                                      }}
                                      isLoading={
                                        selectedId === _id &&
                                        (props.deleteFileLoading || props.updateExerciseLoading)
                                      }
                                    />
                                  ) : (
                                    <>
                                      <AddEditImageBox
                                        isAddBox
                                        isLoading={
                                          selectedId === _id &&
                                          (props.uploadFileLoading || props.updateExerciseLoading)
                                        }
                                        onAddClick={() => {
                                          if (!props.updateExerciseLoading) {
                                            inputFile.current[_id].click();
                                          }
                                        }}
                                      />
                                      <input
                                        type="file"
                                        accept="image/*"
                                        name="exerciseImage"
                                        ref={(el) => {
                                          inputFile.current[_id] = el;
                                        }}
                                        onChange={(e) => {
                                          setSelectedId(_id);
                                          handleFileUpload(e, _id, row);
                                        }}
                                        style={{ display: 'none' }}
                                      />
                                    </>
                                  )}
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Stack direction="row" alignItems="center">
                                  <Icon
                                    icon="fluent:video-switch-24-filled"
                                    height={40}
                                    width={40}
                                    style={{ cursor: 'pointer', color: '#00AB55' }}
                                    onClick={() => {
                                      setSelectedRow(row);
                                      setOpenVideoDialog(true);
                                    }}
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Stack direction="row" alignItems="center" gap={1}>
                                  {/* {exerciseInstructions && (
                                    <Typography
                                      maxWidth="280px"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      textOverflow="ellipsis"
                                    >
                                      {exerciseInstructions}
                                    </Typography>
                                  )} */}
                                  <Typography
                                    onClick={() => {
                                      setSelectedRow(row);
                                      setOpenDescriptionDialog(true);
                                    }}
                                    color="#00AB55"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {exerciseInstructions ? 'Edit' : 'Add'}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Stack direction="row" alignItems="center" gap={1}>
                                  <Typography
                                    onClick={() => {
                                      setSelectedRow(row);
                                      setIsRecommendation(true);
                                      setOpenDescriptionDialog(true);
                                    }}
                                    color="#00AB55"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {programRecommendation ? 'Edit' : 'Add'}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Stack direction="row" alignItems="center" gap={1}>
                                  <Typography
                                    onClick={() => {
                                      setSelectedRow(row);
                                      setOpenExerciseDialog(true);
                                    }}
                                    color="#00AB55"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Manage
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', margin: '30px 0px' }}>
                <Typography variant="h6" align="center">
                  {NO_EXERCISE_FOUND}
                </Typography>
              </Box>
            )}
          </div>
        )}
      </Container>
      {showImageViewer && selectedImage && (
        <ImageViewer
          open={showImageViewer}
          image={selectedImage}
          onClose={() => setShowImageViewer(false)}
        />
      )}
      {openVideoDialog && (
        <ExerciseVideoDialog
          open={openVideoDialog}
          exerciseData={selectedRow}
          onClose={() => {
            setSelectedRow('');
            setOpenVideoDialog(false);
          }}
        />
      )}
      {openDescriptionDialog && (
        <ExerciseDescriptionDialog
          isRecommendation={isRecommendation}
          open={openDescriptionDialog}
          exerciseData={selectedRow}
          onClose={() => {
            setSelectedRow('');
            setIsRecommendation(false);
            setOpenDescriptionDialog(false);
          }}
        />
      )}
      {openExerciseDialog && (
        <MuiltiSelectExerciseDialog
          open={openExerciseDialog}
          exerciseData={selectedRow}
          onClose={() => {
            setSelectedRow('');
            setOpenExerciseDialog(false);
          }}
        />
      )}
    </Page>
  );
}

const mapStateToProps = (state) => ({
  exerciseListData: state.exerciseListData.data,
  exerciseListLoading: state.exerciseListData.loading,

  deleteFileLoading: state.deleteFileData.loading,

  updateExerciseLoading: state.updateExerciseData.loading,

  uploadFileLoading: state.uploadFileData.loading
});

export default connect(mapStateToProps, {
  fetchExerciseList,
  deleteFile,
  updateExercise,
  uploadFile
})(Exercise);
