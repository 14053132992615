import axios from 'axios';
import { UPDATE_EXERCISE } from '../../constants';

export const updateExercise = (exId, data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadUpdateExercise());
  axios
    .put(`/admin/updateExercise/${exId}`, data, config)
    .then((res) => {
      dispatch(setUpdateExercise(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateExercise(err.response));
      onError(err.response);
    });
};

export const loadUpdateExercise = () => ({
  type: UPDATE_EXERCISE.LOAD
});

export const setUpdateExercise = (data) => ({
  type: UPDATE_EXERCISE.SUCCESS,
  payload: data.data
});

export const errorUpdateExercise = (error) => ({
  type: UPDATE_EXERCISE.FAIL,
  payload: error
});
