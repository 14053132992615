import React from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Iconify from './Iconify';

function Textbox(props) {
  return (
    <TextField
      InputProps={{
        inputProps: {
          style: {
            padding: 10
          },
          min: props.type === 'date' ? props.minDate : null
        },
        endAdornment:
          props.inputType === 'password' ? (
            <InputAdornment position="end">
              <IconButton onClick={props.handleShowPassword} edge="end">
                <Iconify icon={props.type === 'text' ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ) : null
      }}
      id={props.id}
      placeholder={props.placeholder}
      variant="outlined"
      type={props.type ? props.type : 'text'}
      margin="none"
      fullWidth={props.fullWidth}
      value={props.value}
      onChange={props.onChange}
      onWheel={(e) => props.type === 'number' && e.target.blur()}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
    />
  );
}

export default Textbox;
