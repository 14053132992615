// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'Codes',
    path: '/dashboard/code',
    icon: getIcon('ion:ticket-sharp')
  },
  {
    title: 'Add Exercise Images',
    path: '/dashboard/exercise',
    icon: getIcon('bxs:image-add')
  }
];

export default sidebarConfig;
