import { combineReducers } from 'redux';

import adminLogin from './adminReducer/adminLogin';
import listCode from './adminReducer/codeList';
import deleteCode from './adminReducer/deleteCode';
import addCode from './adminReducer/addCode';
import addUser from './adminReducer/addUser';
import getCollection from './adminReducer/collection';
import forgotPassword from './adminReducer/forgotPassword';
import changePassword from './adminReducer/changePassword';
import listUser from './adminReducer/userList';
import updateUser from './adminReducer/updateUser';
import exportUsers from './adminReducer/exportUsers';
import exerciseList from './adminReducer/exerciseList';
import uploadFile from './adminReducer/uploadFile';
import deleteFile from './adminReducer/deleteFile';
import updateExercise from './adminReducer/updateExercise';
import allExerciseList from './adminReducer/allExerciseList';
import swapExerciseList from './adminReducer/swapExerciseList';

export default combineReducers({
  adminData: adminLogin,
  codeListData: listCode,
  deleteCodeData: deleteCode,
  addCodeData: addCode,
  collectionData: getCollection,
  forgotPasswordData: forgotPassword,
  changePasswordData: changePassword,
  userListData: listUser,
  addUserData: addUser,
  updateUserData: updateUser,
  exportUserData: exportUsers,
  exerciseListData: exerciseList,
  uploadFileData: uploadFile,
  deleteFileData: deleteFile,
  updateExerciseData: updateExercise,
  allExerciseListData: allExerciseList,
  swapExerciseListData: swapExerciseList
});
