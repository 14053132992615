import axios from 'axios';
import { DownloadCSVorPDF } from '../../utils/appUtils';
import { EXPORT_USERS } from '../../constants';

export const exportUserList = (subscriptionType, userType, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadUserList());

  axios
    .post(
      `/admin/exportUsers?subscriptionStatus=${subscriptionType}&userType=${userType}`,
      {},
      config
    )
    .then((res) => {
      let fileName = `${subscriptionType}`;
      if (userType) fileName = `${fileName}-${userType}`;
      DownloadCSVorPDF(res.data, `${fileName}.csv`);
      dispatch(setUserList(res));
    })
    .catch((err) => {
      dispatch(errorUserList(err.response));
      onError(err.response);
    });
};

export const loadUserList = () => ({
  type: EXPORT_USERS.LOAD
});

export const setUserList = (userList) => ({
  type: EXPORT_USERS.SUCCESS,
  payload: userList.data.data
});

export const errorUserList = (error) => ({
  type: EXPORT_USERS.FAIL,
  payload: error
});
