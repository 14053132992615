import React from 'react';
import { Icon } from '@iconify/react';
import { CircularProgress } from '@mui/material';
import useStyles from './styles';

function AddEditImageBox(props) {
  const { onDeleteClick, image, isAddBox, onAddClick, isLoading, onImageClick } = props;
  const classes = useStyles();

  return isAddBox ? (
    <button
      className={classes.add_image_box}
      disabled={isLoading}
      onClick={(e) => onAddClick && onAddClick(e)}
    >
      {isLoading ? (
        <CircularProgress size={30} style={{ color: '#00AB55' }} />
      ) : (
        <Icon icon="eva:plus-fill" height={30} width={30} />
      )}
    </button>
  ) : (
    <button className={classes.image_box} onClick={(e) => onImageClick && onImageClick(e)}>
      <img src={image} alt="" className={classes.image} />
      <button
        className={classes.close_icon_container}
        disabled={isLoading}
        onClick={(e) => onDeleteClick && onDeleteClick(e)}
      >
        {isLoading ? (
          <CircularProgress size={14} style={{ color: '#ffffff' }} />
        ) : (
          <Icon icon="material-symbols:close" height={16} width={16} />
        )}
      </button>
    </button>
  );
}

export default AddEditImageBox;
