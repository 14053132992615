import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';
import { getErrorMessage } from '../../utils/appUtils';
import useStyles from './styles';
import { uploadFile } from '../../actions/adminActions/uploadFile';
import { deleteFile } from '../../actions/adminActions/deleteFile';
import { updateExercise } from '../../actions/adminActions/updateExercise';
import { setExerciseList } from '../../actions/adminActions/exerciseList';

function ExerciseVideoDialog(props) {
  const { onClose, open, video, exerciseData } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedVideo, setSelectedVideo] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const inputFileRef = useRef();
  const [exerciseListData, setExerciseListData] = useState([]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (exerciseData) {
      setSelectedVideo(exerciseData?.exerciseVideo);
      if (exerciseData?.exerciseVideo) setIsEdit(true);
    }
  }, [exerciseData]);

  useEffect(() => {
    if (props?.exerciseListData) {
      const exercises = props?.exerciseListData?.exercises;
      setExerciseListData(exercises);
    }
  }, [props?.exerciseListData]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      props.uploadFile(file, onError, async (data) => {
        const newImageUrl = data?.url;
        await updateExerciseData(exerciseData?._id, newImageUrl);
        await setSelectedVideo(newImageUrl);
      });
    }
  };

  const updateExerciseData = (id, video, isDelete) => {
    const data = { exerciseVideo: video || '' };
    if (id) {
      props.updateExercise(id, data, onError, async () => {
        let newData = [...exerciseListData];
        newData = newData?.map((el) => {
          if (el?._id === id) {
            el.exerciseVideo = video || '';
          }
          return el;
        });
        const newExerciseData = { ...props.exerciseListData, exercises: newData };
        dispatch(setExerciseList(newExerciseData));
        if (isDelete) setSelectedVideo('');
        // onClose();
      });
    }
  };

  const onSave = () => {
    if (exerciseData?._id) {
      updateExerciseData(exerciseData?._id, selectedVideo);
    }
  };

  const onDelete = () => {
    const data = { url: selectedVideo };
    props.deleteFile(data, onError, async () => {
      updateExerciseData(exerciseData?._id, '', true);
    });
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        className={classes.inner_container}
        width={{ xs: '95%', sm: '80%', md: '60%', lg: '50%', xl: '40%' }}
        height="auto"
        maxHeight="80%"
        p={2.5}
        gap={1.25}
      >
        <Stack
          pb={1}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
        >
          <Typography variant="h6">{isEdit ? 'Update Video' : 'Upload Video'}</Typography>
          <button className={classes.close_icon}>
            <Icon
              icon="material-symbols:close"
              height={25}
              width={25}
              color="#000"
              onClick={onClose}
            />
          </button>
        </Stack>
        <Stack width="100%" overflow="auto" mt={2}>
          <Stack
            alignItems="center"
            justifyContent="center"
            className={classes.videoBox}
            onClick={() => !selectedVideo && inputFileRef.current.click()}
            minHeight={selectedVideo ? 'auto' : 175}
          >
            {props?.uploadFileLoading ? <CircularProgress /> : null}
            {!props?.uploadFileLoading && selectedVideo ? (
              <video autoPlay playsInline muted controls loop className={classes.video}>
                <source src={selectedVideo} />
              </video>
            ) : (
              !props?.uploadFileLoading && (
                <>
                  <Icon icon="formkit:uploadcloud" height={50} />
                  <Typography variant="body2">Browse video file to upload</Typography>
                </>
              )
            )}
            <input
              type="file"
              accept="video/*"
              name="exerciseVideo"
              ref={inputFileRef}
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </Stack>
          {selectedVideo && (
            <Stack mt={3} direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <LoadingButton
                variant="contained"
                loading={props?.deleteFileLoading}
                disabled={
                  props?.uploadFileLoading ||
                  props?.deleteFileLoading ||
                  props?.updateExerciseLoading
                }
                onClick={() => onDelete()}
              >
                Delete Video
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={props?.updateExerciseLoading}
                disabled={
                  !selectedVideo ||
                  props?.uploadFileLoading ||
                  props?.updateExerciseLoading ||
                  props?.deleteFileLoading
                }
                onClick={() => inputFileRef.current.click()}
              >
                Update Video
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  uploadFileLoading: state.uploadFileData.loading,
  deleteFileLoading: state.deleteFileData.loading,
  updateExerciseLoading: state.updateExerciseData.loading,
  exerciseListData: state.exerciseListData.data
});

export default connect(mapStateToProps, {
  uploadFile,
  deleteFile,
  updateExercise
})(ExerciseVideoDialog);
