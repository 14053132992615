import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, Box, Grid, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton, DatePicker } from '@mui/lab';
import moment from 'moment';
import { PASSWORD_ERROR, PASSWORD_NOTMATCH_ERROR } from '../utils/strings';
import { getErrorMessage, passwordRegex } from '../utils/appUtils';
import Textbox from '../components/Textbox';
import Page from '../components/Page';
import { changePassword } from '../actions/adminActions/changePassword';
import { logoutAdmin } from '../actions/adminActions/adminLogin';

function ChangePassword(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inputType, setInputType] = useState({
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password'
  });
  const [error, setError] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const handleShowPassword = (inputName) => {
    setInputType({
      ...inputType,
      [inputName]: inputType[inputName] === 'text' ? 'password' : 'text'
    });
  };

  useEffect(() => {
    const isStrongPassword = passwordRegex.test(newPassword);
    setError({ ...error, newPassword: isStrongPassword || !newPassword ? '' : PASSWORD_ERROR });
  }, [newPassword]);
  useEffect(() => {
    const isSame = newPassword === confirmPassword;
    setError({
      ...error,
      confirmPassword: isSame || !confirmPassword ? '' : PASSWORD_NOTMATCH_ERROR
    });
  }, [confirmPassword]);

  const toastMessage = async (res) => {
    const status = res?.status ?? null;
    const dataResult = res?.data ?? null;
    if (status === 200) {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      await enqueueSnackbar(dataResult, {
        variant: 'success'
      });
      await props.logoutAdmin(navigate);
    }
  };

  const changePassword = () => {
    const data = {
      oldPassword,
      newPassword
    };
    props.changePassword(data, toastMessage, (err) => onError(err));
  };

  const saveDisabled = () => {
    if (
      !oldPassword ||
      !newPassword ||
      !confirmPassword ||
      error.newPassword ||
      error.confirmPassword
    ) {
      return true;
    }
    return false;
  };

  const passwordInput = (
    inputId,
    inputName,
    inputSubName,
    value,
    onchange,
    placeholder,
    inputType,
    type,
    handleShowPassword,
    disabled
  ) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          {/* <Grid item xs={4}>
            <Typography variant="body1">{inputName}</Typography>
            <Typography variant="body">{inputSubName}</Typography>
          </Grid> */}
          <Grid xs={12}>
            <div>
              <Textbox
                id={inputId}
                value={value}
                onChange={onchange}
                placeholder={placeholder}
                fullWidth
                type={type}
                inputType={inputType}
                handleShowPassword={handleShowPassword}
                error={Boolean(error[inputId])}
                helperText={error[inputId]}
                disabled={disabled}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Page title="ChangePassword | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Change Password
          </Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Stack>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '15px 10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {passwordInput(
              'oldPassword',
              'Old Password',
              '',
              oldPassword,
              (e) => setOldPassword(e.target.value),
              'Old Password',
              'password',
              inputType.oldPassword,
              () => handleShowPassword('oldPassword')
            )}
            {passwordInput(
              'newPassword',
              'New Password',
              '',
              newPassword,
              (e) => {
                setNewPassword(e.target.value);
              },
              'New Password',
              'password',
              inputType.newPassword,
              () => handleShowPassword('newPassword')
            )}
            {passwordInput(
              'confirmPassword',
              'Confirm Password',
              '',
              confirmPassword,
              (e) => setConfirmPassword(e.target.value),
              'Confirm Password',
              'password',
              inputType.confirmPassword,
              () => handleShowPassword('confirmPassword'),
              error.newPassword || !newPassword
            )}
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              disabled={saveDisabled()}
              loading={props.changePasswordLoading}
              onClick={() => changePassword()}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  changePasswordLoading: state.changePasswordData.loading
});
export default connect(mapStateToProps, {
  changePassword,
  logoutAdmin
})(ChangePassword);
