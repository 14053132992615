import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  inner_container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: 12
  },
  close_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none'
  },
  video: {
    width: '100%',
    borderRadius: 12,
    maxHeight: 550
  },
  videoBox: {
    width: '100%',
    borderRadius: 12,
    color: '#00AB55',
    border: `2px dashed #00AB55`,
    // minHeight: 175,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in ',
    position: 'relative',
    '&:hover': {
      borderColor: '#007B55',
      color: '#007B55'
    }
  }
}));
