import axios from 'axios';
import { CODE_LIST } from '../../constants';

export const fetchCodeList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadCodeList());

  axios
    .get('/admin/listCodes', config)
    .then((res) => dispatch(setCodeList(res)))
    .catch((err) => {
      dispatch(errorCodeList(err.response));
      onError(err.response);
    });
};

export const loadCodeList = () => ({
  type: CODE_LIST.LOAD
});

export const setCodeList = (codeList) => ({
  type: CODE_LIST.SUCCESS,
  payload: codeList.data.data
});

export const errorCodeList = (error) => ({
  type: CODE_LIST.FAIL,
  payload: error
});
