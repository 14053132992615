export const LOGOUT = 'Logout';
export const YES = 'YES';
export const NO = 'NO';
export const LOGOUT_MESSAGE = 'Are you sure you want to logout?';

export const CODE = 'Code';
export const DELETE_CODE_MESSAGE = 'Are you sure you want to delete code?';

export const NO_CODES_FOUND = 'No codes found, please add codes!';
export const ONLY_CAPITAL_AND_NUMBER_ALLOW = 'Only capital letter and number are allowed!';
export const PASSWORD_ERROR =
  'Must Contain One Uppercase, One Lowercase, One Number and one special case Character with minimum 8 character';
export const PASSWORD_NOTMATCH_ERROR = 'NewPassword and ConfirmPassword should be same!';

export const NO_USERS_FOUND = 'No users found, please add users!';
export const USER = 'User';

export const ENTER_VALID_PHONENO = 'PhoneNo should be 10 digits!';
export const ENTER_VALID_EMAIL = 'Enter valid email!';
export const INACTIVE = 'INACTIVE';
export const ACTIVE = 'ACTIVE';

export const USER_ACTIVATED = 'User account has been activated';
export const USER_DEACTIVATED = 'User account has been deactivated';
export const ACTIVE_USER = 'Are you sure you want to active user?';
export const INACTIVE_USER = 'Are you sure you want to inactive user?';

export const NO_EXERCISE_FOUND = 'No exercise found, please add exercise!';
