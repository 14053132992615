import axios from 'axios';
import { EXERCISE_LIST } from '../../constants';

export const fetchExerciseList = (pageSize, pageNumber, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadExerciseList());

  axios
    .get(`/admin/listExercises?page_size=${pageSize}&page_number=${pageNumber}`, config)
    .then((res) => dispatch(setExerciseList(res.data.data)))
    .catch((err) => {
      dispatch(errorExerciseList(err.response));
      onError(err.response);
    });
};

export const loadExerciseList = () => ({
  type: EXERCISE_LIST.LOAD
});

export const setExerciseList = (exerciseList) => ({
  type: EXERCISE_LIST.SUCCESS,
  payload: exerciseList
});

export const errorExerciseList = (error) => ({
  type: EXERCISE_LIST.FAIL,
  payload: error
});
