import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { countries } from '../utils/appUtils';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

function CountryCodePicker(props) {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={props.value ? props.value : '91'}
      SelectDisplayProps={{
        style: {
          fontSize: 12,
          paddingTop: 10,
          paddingBottom: 10,
          minWidth: 30,
          width: '100%'
        }
      }}
      onChange={props.onChange}
    >
      {countries.map((option, index) => (
        <MenuItem key={`country${index}`} value={option.phone}>
          {`${countryToFlag(option.code)} +${option.phone}`}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CountryCodePicker;
