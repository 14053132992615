import axios from 'axios';
import { UPLOAD_FILE } from '../../constants';

export const uploadFile = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
  };

  const bodyFormData = new FormData();
  bodyFormData.append('image', data);
  dispatch(loadUploadFileData());

  axios
    .post(`/admin/uploadExercise`, bodyFormData, config)
    .then((res) => {
      dispatch(setUploadFileData(res));
      onSuccess(res.data.data);
    })
    .catch((err) => {
      dispatch(errorUploadFileData(err.response));
      onError(err.response);
    });
};

export const loadUploadFileData = () => ({
  type: UPLOAD_FILE.LOAD
});

export const setUploadFileData = (exerciseList) => ({
  type: UPLOAD_FILE.SUCCESS,
  payload: exerciseList.data.data
});

export const errorUploadFileData = (error) => ({
  type: UPLOAD_FILE.FAIL,
  payload: error
});
