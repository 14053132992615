import React from 'react';
import { Icon } from '@iconify/react';
import { CircularProgress, Box, Button, Typography, Modal } from '@mui/material';
import useStyles from './styles';

const style = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

function ImageViewer(props) {
  const { onClose, image, open } = props;
  const classes = useStyles();
  console.log('image', image);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <button className={classes.close_icon}>
          <Icon
            icon="material-symbols:close"
            height={25}
            width={25}
            color="white"
            onClick={onClose}
          />
        </button>
        <img src={image} alt="" className={classes.image} />
      </Box>
    </Modal>
  );
}

export default ImageViewer;
