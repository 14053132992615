import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  image_container: {
    // height: '100%',
    // width: '100%',
    position: 'absolute',
    zIndex: 1101,
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '98%',
    maxHeight: '94%',
    objectFit: 'contain'
  },
  close_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 18,
    right: 18,
    cursor: 'pointer',
    background: 'transparent',
    border: 'none'
  }
}));
