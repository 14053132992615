import axios from 'axios';
import { UPDATE_USER } from '../../constants';

export const updateUser = (data, setActiveInActiveDialog, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadUpdateUser());
  axios
    .put(`admin/updateUser`, data, config)
    .then((res) => {
      dispatch(setUpdateUser(res));
      setActiveInActiveDialog(false);
      onSuccess(data.accountStatus);
    })
    .catch((err) => {
      dispatch(errorUpdateUser(err.response));
      onError(err.response);
    });
};

export const editUser = (data, navigate, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadUpdateUser());
  axios
    .put(`admin/updateUser`, data, config)
    .then((res) => {
      dispatch(setUpdateUser(res));
      navigate(-1);
    })
    .catch((err) => {
      dispatch(errorUpdateUser(err.response));
      onError(err.response);
    });
};

export const loadUpdateUser = () => ({
  type: UPDATE_USER.LOAD
});

export const setUpdateUser = (data) => ({
  type: UPDATE_USER.SUCCESS,
  payload: data.data
});

export const errorUpdateUser = (error) => ({
  type: UPDATE_USER.FAIL,
  payload: error
});
