import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  rootView: {
    '& .MuiOutlinedInput-root': {
      paddingRight: '40px !important'
    }
  },
  paper: {
    background: '#FFF',
    boxShadow: '0 0 4px rgba(0,0,0,0.4)',
    // maxHeight: 200,
    margin: '4px 0',
    borderRadius: 4
  },
  inputField: {
    minWidth: 'calc(100% - 35px) !important',
    paddingRight: '22px !important'
  },
  endIcon: {
    top: 'auto'
  },
  chipContainer: {
    '& .MuiChip-root.Mui-disabled': {
      opacity: 0.85
    }
  }
});

function SearchableDropdown(props) {
  const { label, error, value, placeholder, name, onChange, helperText, optionArray, multiple } =
    props;
  const classes = useStyles();

  return (
    <Autocomplete
      disablePortal
      id={name}
      options={optionArray || []}
      onChange={(e, value) => onChange(value)}
      classes={{
        root: classes.rootView,
        paper: classes.paper,
        input: multiple && classes.inputField,
        endAdornment: classes.endIcon
      }}
      multiple={multiple || false}
      disableCloseOnSelect={multiple || false}
      style={props?.style ? props?.style : {}}
      value={value}
      disabled={props.disabled}
      clearIcon={props.clearIcon || false}
      isOptionEqualToValue={(object, value) => object?.value === value?.value}
      renderTags={(value, getTagProps) => (
        <Stack
          flex={1}
          pr={3}
          direction="row"
          flexWrap="wrap"
          gap={0.5}
          maxHeight={120}
          style={{ overflow: 'auto' }}
          className={classes.chipContainer}
        >
          {value?.map((el, index) => (
            <Chip label={el?.label} {...getTagProps({ index })} />
          ))}
        </Stack>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={name}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

SearchableDropdown.propTypes = {
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  optionArray: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array || PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default SearchableDropdown;
