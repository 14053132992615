import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  inner_container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // alignItems: 'center',
    // justifyContent: 'center',
    background: '#FFF',
    borderRadius: 12,
    minHeight: '300px'
  },
  close_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none'
  }
}));
