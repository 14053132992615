import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';
import { getErrorMessage } from '../../utils/appUtils';
import useStyles from './styles';
import { updateExercise } from '../../actions/adminActions/updateExercise';
import SearchableDropdown from '../SearchableDropdown';
import { fetchAllExerciseList } from '../../actions/adminActions/allExerciseList';
import { fetchSwapExerciseList } from '../../actions/adminActions/swapExerciseList';

function MuiltiSelectExerciseDialog(props) {
  const { onClose, open, exerciseData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [exerciseListData, setExerciseListData] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [submitType, setSubmitType] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    props.fetchAllExerciseList(onError);
    props.fetchSwapExerciseList(exerciseData?.exerciseId, onError);
  }, []);

  useEffect(() => {
    if (Object.keys(props.allExerciseListData)?.length > 0) {
      let newData = structuredClone(props.allExerciseListData?.exercises);
      newData = newData
        ?.map((el) => ({
          label: el?.exerciseName,
          value: el?.exerciseId
        }))
        ?.filter((el) => el?.value !== exerciseData?.exerciseId);
      setExerciseListData(newData);
    }
  }, [props.allExerciseListData]);

  useEffect(() => {
    if (Object.entries(props?.swapExerciseListData)?.length && exerciseListData?.length) {
      const copyExerciseList = structuredClone(exerciseListData);
      const swapExercise = props?.swapExerciseListData?.exercises?.map((el) => {
        if (copyExerciseList?.some((o) => el?.exerciseId === o?.value)) {
          el = copyExerciseList?.find((o) => el?.exerciseId === o?.value);
        }
        return el;
      });
      // const valData = exerciseListData?.filter((el) =>
      //   props?.swapExerciseListData?.exercises?.some((o) => o?.exerciseId === el?.value)
      // );
      setSelectedExercises(swapExercise || []);
    }
  }, [props?.swapExerciseListData, exerciseListData]);

  const onSubmit = (type) => {
    setSubmitType(type);
    const exercises = type === 'Clear' ? [] : selectedExercises?.map((el) => el?.value);
    const newData = { swapExercises: exercises };
    props.updateExercise(exerciseData?._id, newData, onError, () => {
      setSubmitType('');
      onClose();
    });
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        className={classes.inner_container}
        width={{ xs: '95%', sm: '80%', md: '70%', lg: '50%', xl: '40%' }}
        // height="auto"
        // maxHeight="80%"
        minHeight={250}
        p={2.5}
        gap={1.25}
      >
        <Stack
          pb={1}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
        >
          <Typography variant="h6">Manage Exercise</Typography>
          <button className={classes.close_icon}>
            <Icon
              icon="material-symbols:close"
              height={25}
              width={25}
              color="#000"
              onClick={onClose}
            />
          </button>
        </Stack>
        {props?.allExerciseListLoading || props?.swapExerciseListLoading ? (
          <Stack flex={1} height="100%" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack mt={1} width="100%">
            <Typography variant="body1" mb={0.5}>
              Select Exercise
            </Typography>
            <SearchableDropdown
              optionArray={exerciseListData || []}
              name="exercise"
              placeholder="Select Exercise"
              value={selectedExercises}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(value) => setSelectedExercises(value)}
              multiple
            />

            <Stack mt={2} justifyContent="center" direction="row" gap={2}>
              {props?.swapExerciseListData?.exercises?.length ? (
                <LoadingButton
                  variant="contained"
                  loading={submitType === 'Clear' && props?.updateExerciseLoading}
                  disabled={selectedExercises.length === 0 || props?.updateExerciseLoading}
                  onClick={() => setSelectedExercises([])}
                >
                  Clear Data
                </LoadingButton>
              ) : null}
              <LoadingButton
                variant="contained"
                loading={submitType !== 'Clear' && props?.updateExerciseLoading}
                disabled={props?.updateExerciseLoading}
                onClick={() => onSubmit('Submit')}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  allExerciseListData: state.allExerciseListData.data,
  allExerciseListLoading: state.allExerciseListData.loading,

  swapExerciseListData: state.swapExerciseListData.data,
  swapExerciseListLoading: state.swapExerciseListData.loading,

  updateExerciseLoading: state.updateExerciseData.loading
});

export default connect(mapStateToProps, {
  fetchAllExerciseList,
  updateExercise,
  fetchSwapExerciseList
})(MuiltiSelectExerciseDialog);
