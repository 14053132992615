import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, Box, Grid, Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { ENTER_VALID_PHONENO, ENTER_VALID_EMAIL, PASSWORD_ERROR } from '../utils/strings';
import { emailRegex, getErrorMessage, validCodeRegex, passwordRegex } from '../utils/appUtils';
import Textbox from '../components/Textbox';
import Page from '../components/Page';
import { createUser } from '../actions/adminActions/addUser';
import { editUser } from '../actions/adminActions/updateUser';
import CountryCodePicker from '../components/CountryCodePicker';

function AddEditUser(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [lifetimeMember, setLifetimeMember] = useState(false);
  const [countryCode, setCountryCode] = useState('91');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ email: '', phoneNumber: '' });
  const [userId, setUserId] = useState('');
  const { state, pathname } = useLocation();
  const [showLifetime, setShowLifetime] = useState(true);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (state && state.data) {
      setUserId(state?.data?._id);
      setName(state?.data?.name);
      setEmail(state?.data?.email);
      setPhoneNumber(state?.data?.phoneNumber?.slice(-10));
      setCountryCode(state?.data?.phoneNumber?.slice(1, -10));
      setLifetimeMember(state?.data?.personalCode?.lifetimeMember);
      setShowLifetime(!state?.data?.personalCode?.lifetimeMember);
    }
  }, [state]);

  const createUser = () => {
    const isValidEmail = emailRegex.test(email);
    const isValidPassword = passwordRegex.test(password);
    if (
      !isValidEmail ||
      (phoneNumber && phoneNumber.length !== 10) ||
      !isValidPassword ||
      (password && password.length < 8)
    ) {
      setError({
        email: !isValidEmail ? ENTER_VALID_EMAIL : '',
        phoneNumber: phoneNumber && phoneNumber.length !== 10 ? ENTER_VALID_PHONENO : '',
        password: !isValidPassword ? PASSWORD_ERROR : ''
      });
    } else {
      const data = {
        email,
        lifetimeMember
      };
      if (phoneNumber) data.phoneNumber = `+${countryCode}${phoneNumber}`;
      if (name) data.name = name;
      if (password) data.password = password;
      props.createUser(data, navigate, (err) => onError(err));
    }
  };
  const updateUser = () => {
    const data = {
      id: userId,
      name,
      email,
      phoneNumber
    };
    if (state.data?.phoneNumber !== phoneNumber) data.phoneNumber = `+${countryCode}${phoneNumber}`;
    if (state.data?.name !== name) data.name = name;
    if (state.data?.name !== email) data.email = email;
    if (state.data?.lifetimeMember !== lifetimeMember && lifetimeMember)
      data.lifetimeMember = lifetimeMember;
    props.editUser(data, navigate, (err) => onError(err));
  };

  const saveDisabled = () => {
    if (pathname === '/dashboard/add/user' && !email) {
      return true;
    }
    return false;
  };

  const userInput = (inputId, inputName, value, onchange, placeholder, type) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item xs={4} style={{ wordWrap: 'break-word' }}>
            <Typography variant="body1">{inputName}</Typography>
          </Grid>
          <Grid xs={8}>
            {type === 'checkbox' ? (
              <Checkbox checked={value} onChange={onchange} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {inputId === 'phoneNumber' && (
                  <div style={{ marginRight: 5 }}>
                    <CountryCodePicker
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                  </div>
                )}
                <Textbox
                  id={inputId}
                  value={value}
                  onChange={onchange}
                  placeholder={placeholder}
                  fullWidth
                  type={type}
                  error={Boolean(error[inputId])}
                  helperText={error[inputId]}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Page title="User | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {pathname === '/dashboard/add/user' ? 'Add New User' : 'Edit User'}
          </Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Stack>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '15px 10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {userInput('name', 'Name', name, (e) => setName(e.target.value), 'Name')}
            {userInput(
              'email',
              'Email',
              email,
              (e) => {
                setEmail(e.target.value.toLowerCase());
                setError({ ...error, email: '' });
              },
              'Email'
            )}
            {userInput(
              'phoneNumber',
              'Phone Number',
              phoneNumber,
              (e) => {
                setPhoneNumber(e.target.value);
                setError({ ...error, phoneNumber: '' });
              },
              'Phone Number',
              'number'
            )}
            {pathname === '/dashboard/add/user' &&
              userInput(
                'password',
                'Password',
                password,
                (e) => {
                  setPassword(e.target.value);
                  setError({ ...error, password: '' });
                },
                'Password',
                'string'
              )}
            {(pathname === '/dashboard/add/user' || showLifetime) &&
              userInput(
                'lifetimeMember',
                'Lifetime Member',
                lifetimeMember,
                (e) => {
                  setLifetimeMember(!lifetimeMember);
                },
                '',
                'checkbox'
              )}
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              disabled={saveDisabled()}
              loading={props.addUserLoading}
              onClick={() => (pathname === '/dashboard/add/user' ? createUser() : updateUser())}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  addUserData: state.addUserData.data,
  addUserLoading: state.addUserData.loading
});
export default connect(mapStateToProps, {
  createUser,
  editUser
})(AddEditUser);
