import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ForgotPasswordForm(props) {
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      props.resetPassword(values);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton
          style={{ marginTop: 30 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={props.loading}
        >
          Reset Password
        </LoadingButton>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
          fontSize="50"
        >
          <Link component={RouterLink} variant="subtitle2" to="/" underline="hover">
            Login
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
