import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
import { getErrorMessage } from '../utils/appUtils';
// components;
import Page from '../components/Page';
import { forgotPassword } from '../actions/adminActions/forgotPassword';
import { ForgotPasswordForm } from '../sections/authentication/forgotPassword';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

function ForgotPassword(props) {
  const { enqueueSnackbar } = useSnackbar();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const toastMessage = (res) => {
    const status = res?.status ?? null;
    const dataResult = res?.data ?? null;
    if (status === 200) {
      enqueueSnackbar(dataResult, {
        variant: 'success'
      });
    }
  };

  const resetPassword = (value) => {
    props.forgotPassword(
      {
        email: value.email
      },
      toastMessage,
      (err) => onError(err)
    );
  };

  return (
    <RootStyle title="Forgot Password | Physique Lab App">
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Enter your email to reset password!
        </Typography>
        <img src="/static/illustrations/illustration_login.png" alt="login" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your email below.</Typography>
          </Stack>

          <ForgotPasswordForm resetPassword={resetPassword} loading={props.passwordDataLoading} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

const mapStateToProps = (state) => ({
  passwordData: state.forgotPasswordData.data,
  passwordDataLoading: state.forgotPasswordData.loading
});

export default connect(mapStateToProps, {
  forgotPassword
})(ForgotPassword);
