import axios from 'axios';
import { ADD_CODE } from '../../constants';

export const createCode = (data, navigate, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(addCode());
  axios
    .post(`admin/createCode`, data, config)
    .then((res) => {
      dispatch(setCode(res));
      navigate(-1);
    })
    .catch((err) => {
      dispatch(errorCode(err.response));
      onError(err.response);
    });
};

export const addCode = () => ({
  type: ADD_CODE.LOAD
});

export const setCode = (data) => ({
  type: ADD_CODE.SUCCESS,
  payload: data.data
});

export const errorCode = (error) => ({
  type: ADD_CODE.FAIL,
  payload: error
});
