import axios from 'axios';
import { DELETE_CODE } from '../../constants';

export const deleteCode = (cID, setDeleteDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadDeleteCode());
  axios
    .delete(`/admin/deleteCode?id=${cID}`, config)
    .then((res) => {
      dispatch(setDeleteCode(res));
      setDeleteDialog(false);
    })
    .catch((err) => {
      dispatch(errorDeleteCode(err.response));
      onError(err.response);
    });
};

export const loadDeleteCode = () => ({
  type: DELETE_CODE.LOAD
});

export const setDeleteCode = (data) => ({
  type: DELETE_CODE.SUCCESS,
  payload: data
});

export const errorDeleteCode = (error) => ({
  type: DELETE_CODE.FAIL,
  payload: error
});
