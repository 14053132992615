import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, Box, Grid, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton, DatePicker } from '@mui/lab';
import moment from 'moment';
import { ONLY_CAPITAL_AND_NUMBER_ALLOW } from '../utils/strings';
import { getErrorMessage, validCodeRegex } from '../utils/appUtils';
import Textbox from '../components/Textbox';
import Page from '../components/Page';
import { createCode } from '../actions/adminActions/addCode';

function AddCode(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [expiryDate, setExpiryDate] = useState(moment().format('YYYY-MM-DD'));
  const [discount, setDiscount] = useState();
  const [error, setError] = useState({ code: '' });

  const { state, pathname } = useLocation();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const isValidCode = validCodeRegex.test(code);
    setError({ code: isValidCode ? '' : ONLY_CAPITAL_AND_NUMBER_ALLOW });
  }, [code]);

  const createCode = () => {
    const data = {
      code,
      expiryDate: new Date(expiryDate).toISOString(),
      discount
    };

    props.createCode(data, navigate, (err) => onError(err));
  };

  const saveDisabled = () => {
    if (
      pathname === '/dashboard/add/code' &&
      (!code || !expiryDate || props.addCodeLoading || !discount || error.code)
    ) {
      return true;
    }
    return false;
  };

  const codeInput = (
    inputId,
    inputName,
    inputSubName,
    value,
    onchange,
    placeholder,
    renderInput,
    type
  ) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body1">{inputName}</Typography>
            <Typography variant="body">{inputSubName}</Typography>
          </Grid>
          <Grid xs={8}>
            <div>
              <Textbox
                id={inputId}
                value={value}
                onChange={onchange}
                placeholder={placeholder}
                fullWidth
                type={type}
                minDate={type === 'date' ? moment().format('YYYY-MM-DD') : null}
                error={Boolean(error[inputId])}
                helperText={error[inputId]}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Page title="Code | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add New Code
          </Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Stack>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '15px 10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {codeInput('code', 'Code', '', code, (e) => setCode(e.target.value), 'Code')}
            {codeInput(
              'expiryDate',
              'Code Expiry Date',
              '',
              expiryDate,
              (e) => {
                setExpiryDate(e.target.value);
              },
              'Expiry Date',
              null,
              'date'
            )}
            {codeInput(
              'discount',
              'Discount',
              '(in %)',
              discount,
              (e) => setDiscount(e.target.value),
              'Discount',
              'number'
            )}
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              disabled={saveDisabled()}
              loading={props.addCodeLoading}
              onClick={() => createCode()}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  addCodeData: state.addCodeData.data,
  addCodeLoading: state.addCodeData.loading
});
export default connect(mapStateToProps, {
  createCode
})(AddCode);
