import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Modal, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getErrorMessage } from '../../utils/appUtils';
import useStyles from './styles';
import { updateExercise } from '../../actions/adminActions/updateExercise';
import { setExerciseList } from '../../actions/adminActions/exerciseList';

const ReactQuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
};

const ReactQuillFormats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent'];

function ExerciseDescriptionDialog(props) {
  const { onClose, open, exerciseData, isRecommendation } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const [description, setDescription] = useState('');
  const [exerciseListData, setExerciseListData] = useState([]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (exerciseData && Object.entries(exerciseData)?.length) {
      setDescription(
        isRecommendation
          ? exerciseData?.programRecommendation || ''
          : exerciseData?.exerciseInstructions || ''
      );
      if (
        (exerciseData?.exerciseInstructions && !isRecommendation) ||
        (isRecommendation && exerciseData?.programRecommendation)
      ) {
        setIsEdit(true);
      }
    }
  }, [exerciseData, isRecommendation]);

  useEffect(() => {
    if (props?.exerciseListData) {
      const exercises = props?.exerciseListData?.exercises;
      setExerciseListData(exercises);
    }
  }, [props?.exerciseListData]);

  const onSubmit = () => {
    const newData = isRecommendation
      ? { programRecommendation: description }
      : { exerciseInstructions: description };
    props.updateExercise(exerciseData?._id, newData, onError, async () => {
      let newData = [...exerciseListData];
      newData = newData?.map((el) => {
        if (el?._id === exerciseData?._id) {
          if (isRecommendation) {
            el.programRecommendation = description || '';
          } else {
            el.exerciseInstructions = description || '';
          }
        }
        return el;
      });
      const newExerciseData = { ...props.exerciseListData, exercises: newData };
      dispatch(setExerciseList(newExerciseData));
      onClose();
    });
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        className={classes.inner_container}
        width={{ xs: '95%', sm: '80%', md: '60%', lg: '50%', xl: '40%' }}
        height="auto"
        maxHeight="80%"
        p={2.5}
        gap={1.25}
      >
        <Stack
          pb={1}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
        >
          <Typography variant="h6">
            {isEdit
              ? `Update ${isRecommendation ? 'Recommendation' : 'Description'}`
              : `Add ${isRecommendation ? 'Recommendation' : 'Description'}`}
          </Typography>
          <button className={classes.close_icon}>
            <Icon
              icon="material-symbols:close"
              height={25}
              width={25}
              color="#000"
              onClick={onClose}
            />
          </button>
        </Stack>
        <Stack mt={1} width="100%" overflow="auto">
          <Typography variant="body1" mb={0.5}>
            Description
          </Typography>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={(value) => setDescription(value)}
            modules={ReactQuillModules}
            formats={ReactQuillFormats}
            placeholder="Type your note here"
            style={{ width: '100%' }}
          />
          {/* <TextField
            variant="outlined"
            type="text"
            name="description"
            margin="none"
            fullWidth
            value={description}
            multiline
            rows={4}
            onChange={(e) => setDescription(e.target.value)}
          /> */}
        </Stack>
        <Stack mt={2} justifyContent="center" direction="row">
          <LoadingButton
            variant="contained"
            loading={props?.updateExerciseLoading}
            disabled={!description?.trim() && !isEdit}
            onClick={() => onSubmit()}
          >
            {isEdit ? 'Update' : 'Add'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  updateExerciseLoading: state.updateExerciseData.loading,
  exerciseListData: state.exerciseListData.data
});

export default connect(mapStateToProps, {
  updateExercise
})(ExerciseDescriptionDialog);
