import axios from 'axios';
import { GET_COLLECTION } from '../../constants';

export const fetchCollection = (codeId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadGetCollection());

  axios
    .get(`/admin/codeStatics?id=${codeId}`, config)
    .then((res) => dispatch(setGetCollection(res)))
    .catch((err) => {
      dispatch(errorGetCollection(err.response));
      onError(err.response);
    });
};

export const loadGetCollection = () => ({
  type: GET_COLLECTION.LOAD
});

export const setGetCollection = (collection) => ({
  type: GET_COLLECTION.SUCCESS,
  payload: collection.data.data
});

export const errorGetCollection = (error) => ({
  type: GET_COLLECTION.FAIL,
  payload: error
});
