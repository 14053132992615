const ADMIN_LOGIN = {
  LOAD: 'LOAD_ADMIN_LOGIN',
  SUCCESS: 'SUCCESS_ADMIN_LOGIN',
  FAIL: 'FAIL_ADMIN_LOGIN'
};

const CODE_LIST = {
  LOAD: 'LOAD_CODE_DATA',
  SUCCESS: 'SUCCESS_CODE_DATA',
  FAIL: 'FAIL_CODE_DATA'
};

const DELETE_CODE = {
  LOAD: 'LOAD_DELETE_CODE',
  SUCCESS: 'SUCCESS_DELETE_CODE',
  FAIL: 'FAIL_DELETE_CODE'
};

const ADD_CODE = {
  LOAD: 'LOAD_ADD_CODE',
  SUCCESS: 'SUCCESS_ADD_CODE',
  FAIL: 'FAIL_ADD_CODE'
};

const GET_COLLECTION = {
  LOAD: 'LOAD_GET_COLLECTION',
  SUCCESS: 'SUCCESS_GET_COLLECTION',
  FAIL: 'FAIL_GET_COLLECTION'
};

const FORGOT_PASSWORD = {
  LOAD: 'LOAD_FORGOT_PASSWORD',
  SUCCESS: 'SUCCESS_FORGOT_PASSWORD',
  FAIL: 'FAIL_FORGOT_PASSWORD'
};

const CHANGE_PASSWORD = {
  LOAD: 'LOAD_CHANGE_PASSWORD',
  SUCCESS: 'SUCCESS_CHANGE_PASSWORD',
  FAIL: 'FAIL_CHANGE_PASSWORD'
};

const USER_LIST = {
  LOAD: 'LOAD_USER_DATA',
  SUCCESS: 'SUCCESS_USER_DATA',
  FAIL: 'FAIL_USER_DATA'
};

const ADD_USER = {
  LOAD: 'LOAD_ADD_USER',
  SUCCESS: 'SUCCESS_ADD_USER',
  FAIL: 'FAIL_ADD_USER'
};

const UPDATE_USER = {
  LOAD: 'LOAD_UPDATE_USER',
  SUCCESS: 'SUCCESS_UPDATE_USER',
  FAIL: 'FAIL_UPDATE_USER'
};

const EXPORT_USERS = {
  LOAD: 'LOAD_USERS_DATA',
  SUCCESS: 'SUCCESS_USERS_DATA',
  FAIL: 'FAIL_USERS_DATA'
};

const EXERCISE_LIST = {
  LOAD: 'LOAD_EXERCISE_DATA',
  SUCCESS: 'SUCCESS_EXERCISE_DATA',
  FAIL: 'FAIL_EXERCISE_DATA'
};

const ALL_EXERCISE_LIST = {
  LOAD: 'LOAD_ALL_EXERCISE_DATA',
  SUCCESS: 'SUCCESS_ALL_EXERCISE_DATA',
  FAIL: 'FAIL_ALL_EXERCISE_DATA'
};

const UPLOAD_FILE = {
  LOAD: 'LOAD_UPLOAD_FILE',
  SUCCESS: 'SUCCESS_UPLOAD_FILE',
  FAIL: 'FAIL_UPLOAD_FILE'
};

const DELETE_FILE = {
  LOAD: 'LOAD_DELETE_FILE',
  SUCCESS: 'SUCCESS_DELETE_FILE',
  FAIL: 'FAIL_DELETE_FILE'
};

const UPDATE_EXERCISE = {
  LOAD: 'LOAD_UPDATE_EXERCISE',
  SUCCESS: 'SUCCESS_UPDATE_EXERCISE',
  FAIL: 'FAIL_UPDATE_EXERCISE'
};

const SWAP_EXERCISE_LIST = {
  LOAD: 'LOAD_SWAP_EXERCISE_LIST',
  SUCCESS: 'SUCCESS_SWAP_EXERCISE_LIST',
  FAIL: 'FAIL_SWAP_EXERCISE_LIST'
};

export {
  ADMIN_LOGIN,
  CODE_LIST,
  DELETE_CODE,
  ADD_CODE,
  GET_COLLECTION,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  USER_LIST,
  ADD_USER,
  UPDATE_USER,
  EXPORT_USERS,
  EXERCISE_LIST,
  UPLOAD_FILE,
  DELETE_FILE,
  UPDATE_EXERCISE,
  ALL_EXERCISE_LIST,
  SWAP_EXERCISE_LIST
};
