import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  add_image_box: {
    height: 55,
    width: 55,
    borderRadius: 6,
    cursor: 'pointer',
    border: `1px solid #00AB55`,
    backgroundColor: '#FFFFFF',
    color: '#00AB55'
  },
  image_box: {
    height: 55,
    width: 55,
    border: 'none',
    borderRadius: 6,
    position: 'relative',
    cursor: 'pointer',
    background: '#FFFFFF'
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 6
  },
  close_icon_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
    cursor: 'pointer',
    background: '#00AB55',
    height: 20,
    width: 20,
    border: 'none',
    borderRadius: '50%',
    color: '#FFFFFF'
  }
}));
