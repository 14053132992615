import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box
} from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getErrorMessage } from '../utils/appUtils';
import Page from '../components/Page';
import CommonDialog from '../components/CommonDialog';
import { CODE, DELETE_CODE_MESSAGE, NO_CODES_FOUND } from '../utils/strings';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { fetchCodeList } from '../actions/adminActions/codeList';
import { deleteCode } from '../actions/adminActions/deleteCode';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'code', label: 'Code', alignRight: false },
  { id: 'expiryDate', label: 'Expiry Date', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'createdAt', label: 'Created On', alignRight: false },
  { id: '' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_code) => _code.code.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function ActionButton(props) {
  return (
    <Button
      variant="contained"
      style={{
        width: 100,
        paddingTop: 8,
        paddingBottom: 8
      }}
      onClick={props.onClick}
    >
      {props.value}
    </Button>
  );
}

function Code(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('code');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [codeListData, setCodeListData] = useState([]);
  const [codeId, setCodeId] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    props.fetchCodeList((err) => onError(err));
  }, [props.deleteCodeData]);

  useEffect(() => {
    const data = props.codeListData ? props.codeListData : [];
    setCodeListData(data);
  }, [props.codeListData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - codeListData.length) : 0;

  const filteredCodes = applySortFilter(codeListData, getComparator(order, orderBy), filterName);

  const isCodeNotFound = filteredCodes.length === 0;

  const onDelete = (id) => {
    props.deleteCode(id, setDeleteDialog, (err) => onError(err));
  };

  return (
    <Page title="Code | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Codes
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add/code"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Code
          </Button>
        </Stack>
        {props.codeListLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {codeListData.length > 0 ? (
              <Card>
                <UserListToolbar
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  searchPlaceholder="Search Code..."
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={codeListData.length}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredCodes
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { _id, code, expiryDate, discount, createdAt } = row;

                            return (
                              <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                                <TableCell component="th" scope="row">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {code}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {moment(expiryDate).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell align="left">{discount}</TableCell>
                                <TableCell align="left">
                                  {moment(createdAt).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell align="left">
                                  <div style={{ display: 'flex' }}>
                                    <ActionButton
                                      value="Collection"
                                      onClick={() =>
                                        navigate('/dashboard/collection', {
                                          state: { id: _id }
                                        })
                                      }
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <UserMoreMenu
                                    onDelete={() => {
                                      setCodeId(_id);
                                      setDeleteDialog(true);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isCodeNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={codeListData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6" align="center">
                  {NO_CODES_FOUND}
                </Typography>
              </Box>
            )}
          </div>
        )}
      </Container>
      <CommonDialog
        open={deleteDialog}
        close={() => setDeleteDialog(false)}
        onSuccess={() => onDelete(codeId)}
        dialogTitle={CODE}
        dialogMessage={DELETE_CODE_MESSAGE}
        loading={props.deleteCodeDataLoading}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  codeListData: state.codeListData.data,
  codeListLoading: state.codeListData.loading,

  deleteCodeData: state.deleteCodeData.data,
  deleteCodeDataLoading: state.deleteCodeData.loading
});

export default connect(mapStateToProps, {
  fetchCodeList,
  deleteCode
})(Code);
