import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Card,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  CardActions
} from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../utils/appUtils';
import Page from '../components/Page';
import { fetchCollection } from '../actions/adminActions/codeStatics';

function Collection(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [collectionData, setCollectionData] = useState([]);
  const { state, pathname } = useLocation();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    props.fetchCollection(state.id, (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.collectionData;
    setCollectionData(data);
  }, [props.collectionData]);

  return (
    <Page title="Collection | Physique Lab App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Collection
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/code">
            Back
          </Button>
        </Stack>
        {props.collectionLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '15px 10px',
                  height: 200
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 500
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Yearly Collection <span style={{ fontSize: 15 }}>(in $)</span>
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    {collectionData.yearlyCollection}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '15px 10px',
                  height: 200
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 500
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Monthly Collection <span style={{ fontSize: 15 }}>(in $)</span>
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    {collectionData.monthlyCollection}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 200
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: '10px',
                    paddingInline: { xs: '15px', sm: '0px' }
                  }}
                >
                  <Typography variant="h4" gutterBottom textAlign="center">
                    Active Accounts this Months{`(${collectionData?.users?.length})`}
                  </Typography>
                  <div
                    style={{
                      overflow: 'auto',
                      textAlign: !collectionData?.users?.length ? 'center' : 'left',
                      width: '100%',
                      paddingInline: '20px'
                    }}
                  >
                    <Stack variant="subtitle2">
                      {!collectionData?.users?.length
                        ? 'No data found'
                        : collectionData?.users?.map((user, index) => (
                            <li key={index} style={{ marginTop: '5px' }}>
                              {user?.user?.email}
                            </li>
                          ))}
                    </Stack>
                  </div>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '15px 10px',
                  height: 200
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 500
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Lead to Payment
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    {collectionData.leadToPayment}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  collectionData: state.collectionData.data,
  collectionLoading: state.collectionData.loading
});
export default connect(mapStateToProps, {
  fetchCollection
})(Collection);
