import axios from 'axios';
import { ALL_EXERCISE_LIST } from '../../constants';

export const fetchAllExerciseList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadAllExerciseList());

  axios
    .get(`/admin/listExercises`, config)
    .then((res) => dispatch(setAllExerciseList(res.data.data)))
    .catch((err) => {
      dispatch(errorAllExerciseList(err.response));
      onError(err.response);
    });
};

export const loadAllExerciseList = () => ({
  type: ALL_EXERCISE_LIST.LOAD
});

export const setAllExerciseList = (exerciseList) => ({
  type: ALL_EXERCISE_LIST.SUCCESS,
  payload: exerciseList
});

export const errorAllExerciseList = (error) => ({
  type: ALL_EXERCISE_LIST.FAIL,
  payload: error
});
