import axios from 'axios';
import { CHANGE_PASSWORD } from '../../constants';

export const changePassword = (data, toastMessage, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(addChangePassword());
  axios
    .post(`admin/changePassword`, data, config)
    .then((res) => {
      dispatch(setChangePassword(res));
      toastMessage(res.data);
    })
    .catch((err) => {
      dispatch(errorChangePassword(err.response));
      onError(err.response);
    });
};

export const addChangePassword = () => ({
  type: CHANGE_PASSWORD.LOAD
});

export const setChangePassword = (data) => ({
  type: CHANGE_PASSWORD.SUCCESS,
  payload: data.data
});

export const errorChangePassword = (error) => ({
  type: CHANGE_PASSWORD.FAIL,
  payload: error
});
