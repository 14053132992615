import { Navigate, useRoutes } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Login';
import User from './pages/User';
import Code from './pages/Code';
import NotFound from './pages/Page404';
import setAuthToken from './utils/setAuthToken';
import store from './store';
import { logoutAdmin, setAdminLogin } from './actions/adminActions/adminLogin';
import AddCode from './pages/AddCode';
import Collection from './pages/Collection';
import ForgotPassword from './pages/forgotPassword';
import ChangePassword from './pages/ChangePassword';
import AddEditUser from './pages/AddEditUser';
import Exercise from './pages/Exercise';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  store.dispatch(setAdminLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutAdmin());
    window.location.href = '/login';
  }
}

export default function Router() {
  return useRoutes([
    localStorage.jwtToken
      ? {
          path: '/',
          element: <DashboardLayout />,
          children: [
            { path: '/', element: <Navigate to="/dashboard/user" /> },
            { path: '/dashboard/user', element: <User /> },
            { path: '/dashboard/code', element: <Code /> },
            { path: '/dashboard/add/code', element: <AddCode /> },
            { path: '/dashboard/add/user', element: <AddEditUser /> },
            { path: '/dashboard/edit/user', element: <AddEditUser /> },
            { path: '/dashboard/collection', element: <Collection /> },
            { path: '/dashboard/forgotPassword', element: <ForgotPassword /> },
            { path: '/dashboard/change-password', element: <ChangePassword /> },
            { path: '/dashboard/exercise', element: <Exercise /> }
          ]
        }
      : {
          path: '/',
          element: <LogoOnlyLayout />,
          children: [
            { path: '/', element: <Navigate to="/login" /> },
            { path: 'login', element: <Login /> }
          ]
        },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/forgotPassword', element: <ForgotPassword /> }
  ]);
}
